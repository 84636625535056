@import '../../../../styles/index';

.legend-element {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1.375;
  white-space: nowrap;
  font-weight: $font-weight-medium;

  &::before {
    content: '';
    display: block;
    border-style: solid;
    border-width: 0.125rem;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}

.green {
  color: $green-9;

  &::before {
    background-color: $green-4;
  }
}

.orange {
  color: $orange-9;

  &::before {
    background-color: $orange-4;
  }
}

.turquoise {
  color: $turquoise-9;

  &::before {
    background-color: $turquoise-4;
  }
}
