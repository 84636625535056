@import '../../../styles/index';

.base {
  background-color: $neutral-0;
  padding: 1.25rem 1.25rem 1.5rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 48px rgba(0, 0, 0, 0.08);
  border-radius: 0.375rem;
  font-size: $font-size-16;

  @include media-from(sm) {
    font-size: $font-size-18;
  }
}

.status {
  padding: 0.4375rem 0;
  text-align: center;
  font-weight: $font-weight-medium;
  line-height: 1.375;
}

.address {
  margin: 1rem auto 0 auto;
  text-align: center;
  line-height: 1.6;
  font-weight: $font-weight-light;
}

.link {
  justify-content: center;
  margin-top: 1rem;

  &-mobile {
    display: none;

    @include media-to(sm) {
      display: flex;
    }
  }

  &-desktop {
    display: none;

    @include media-from(sm) {
      display: flex;
    }
  }
}

.green {
  background-color: $green-3;
  color: $green-9;
}

.orange {
  background-color: $orange-3;
  color: $orange-9;
}

.turquoise {
  background-color: $turquoise-3;
  color: $turquoise-9;
}
