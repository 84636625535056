@import '../../styles/index';

.base {
  max-width: 100%;

  @include media-to(sm) {
    max-width: unset;
    overflow-x: scroll;
    padding-left: 1rem;
    transform: translateX(-1rem);
    width: calc(100% + 2rem);
    -webkit-overflow-scrolling: touch;
  }

  &.has-additional-headline table {
    thead,
    .additional-headline-row {
      background-color: $neutral-0;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-top: 0 !important;
      font-size: $font-size-16;
      line-height: 1.3;

      @include media-from(sm) {
        font-size: $font-size-20;
      }

      div {
        color: $neutral-12;
        font-weight: $font-weight-bold;
      }

      strong {
        font-weight: $font-weight-bold;
      }
    }

    tbody {
      tr:not(:first-child) {
        border-top-style: solid;
        border-top-width: 1px;
      }

      tr.additional-headline-row + tr {
        border-top: none;
      }
    }
  }

  &.has-head-column table tr th:first-child {
    border-right-style: solid;
    border-right-width: 1px;
  }

  table {
    background-color: $neutral-0;
    border: none;
    border-collapse: collapse;
    height: auto !important;
    width: 100% !important;

    &.auto {
      table-layout: auto;
    }

    &.fixed {
      table-layout: fixed;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    tr {
      border: none;
      height: auto;
    }

    caption {
      font-size: $font-size-16;
      font-weight: $font-weight-medium;
      margin-bottom: 1rem;
      padding-inline: 1rem;
      text-align: left;

      @include media-from(sm) {
        font-size: $font-size-24;
        text-align: center;
      }
    }

    thead {
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    th {
      font-size: $font-size-16;

      @include media-from(sm) {
        font-size: $font-size-20;
      }
    }

    tbody {
      color: $neutral-12;
      font-size: $font-size-14;

      @include media-from(sm) {
        font-size: $font-size-18;
      }
    }

    th,
    td {
      border-style: none;
      max-width: 260px;
      padding: 1rem;
      position: relative;
      text-align: left;

      @include media-from(sm) {
        padding: 1.875rem;
      }

      p {
        margin: 0;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    tr {
      &:not(.additional-headline-row) {
        th {
          line-height: 1.3;
          padding: 1rem;

          strong {
            font-weight: $font-weight-bold;
          }
        }
        th {
          @include media-from(sm) {
            padding: 2rem;
          }
        }

        &.alternate-columns:not(:first-child) {
          border-top-style: solid;
          border-top-width: 1px;
        }
      }
    }
  }
}

@mixin table-color-theme(
  $base-color,
  $banded-row-color,
  $row-border-color //only used when additional headline is existing
) {
  thead {
    border-bottom-color: $base-color;
  }
  &.has-head-column table thead {
    border-bottom-color: $row-border-color;
  }

  &:not(.has-additional-headline) table {
    tbody tr:not(.alternate-columns):nth-child(odd),
    tr.alternate-columns > *:nth-child(even) {
      background-color: $banded-row-color;
    }

    tr.alternate-columns {
      border-top-color: $row-border-color;
    }
  }

  &.has-additional-headline table {
    thead,
    .additional-headline-row {
      background-color: $banded-row-color;
      border-bottom-color: $base-color;
    }
  }

  tbody {
    tr:not(:first-child) {
      border-top-color: $row-border-color;
    }
  }

  th {
    border-right-color: $row-border-color;
  }
}

.blue {
  @include table-color-theme(
    $turquoise-9,
    $turquoise-2,
    $turquoise-4
  );
}

.red {
  @include table-color-theme($red-9, $red-2, $red-4);
}

.green {
  @include table-color-theme(
    $green-9,
    $green-2,
    $green-4
  );
}

.violet {
  @include table-color-theme(
    $violet-9,
    $violet-2,
    $violet-4
  );
}

.orange {
  @include table-color-theme(
    $orange-9,
    $orange-2,
    $orange-4
  );
}

.surrogate {
  @include table-color-theme(
    $surrogate-9,
    $surrogate-2,
    $surrogate-4
  );
}
